import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteVM } from '@models/backend-generated-models/models';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SiteStateService {
  selectedSiteSource = new BehaviorSubject<SiteVM | null>(null);
  selectedPortalIdSource = new BehaviorSubject<number | null>(null);

  selectedSite$ = this.selectedSiteSource.pipe(
    tap((site) => {
      if (site) {
        localStorage.setItem('siteId', site.siteId!.toString());
      }
    })
  );

  selectedPortalId$ = this.selectedPortalIdSource.pipe(
    tap((portalId) => {
      if (portalId) {
        localStorage.setItem('portalId', portalId.toString());
      }
    })
  );

  constructor() {}

  /**
   * Sets the currently selected portal ID.
   * @param portalId - The portal ID to set as selected.
   */
  setSelectedPortalId(portalId: number | null): void {
    this.selectedPortalIdSource.next(portalId);
  }
}
