import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { SiteService } from "@api/site.service";
import { filter, switchMap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { FPCategoryVM, SiteVM } from "@models/backend-generated-models/models";
import { SiteStateService } from "@states/site-state.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalReportsComponent } from "../../modal/modal-reports/modal-reports.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UserMenuService } from '@api/user-menu.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  @ViewChildren("mainItem") divs: QueryList<ElementRef<HTMLDivElement>> = new QueryList<ElementRef<HTMLDivElement>>();

  mainItems: {
    id: ('I' | 'B' | 'O' | 'A')[],
    name: 'My Utilities' | 'Live Data' | 'My Reports and Operations',
    hoverColorHex: string,
    imageUrl: string;
    fpResourceCategories: BehaviorSubject<FPCategoryVM[]>;
  }[] = [
    {
      id: ['I'],
      name: 'My Utilities',
      hoverColorHex: '#5597A7',
      imageUrl: 'assets/images/svg/category_utility.svg',
      fpResourceCategories: new BehaviorSubject<FPCategoryVM[]>([])
    },
    {
      id: ['B', 'A'],
      name: 'Live Data',
      hoverColorHex: '#ED5E19',
      imageUrl: 'assets/images/svg/category_facility.svg',
      fpResourceCategories: new BehaviorSubject<FPCategoryVM[]>([])
    },
    {
      id: ['O'],
      name: 'My Reports and Operations',
      hoverColorHex: '#A0CC3B',
      imageUrl: 'assets/images/svg/category_reports.svg',
      fpResourceCategories: new BehaviorSubject<FPCategoryVM[]>([])
    }
  ];

  isSpecificPortal: boolean = false; // Flag for the specific portal

  constructor(
    private siteService: SiteService,
    private siteStateService: SiteStateService,
    public dialog: MatDialog,
    private userMenuService: UserMenuService
  ) {}

  reportViewerURL$ = this.userMenuService.getReportViewerURL$().pipe();

  ngOnInit(): void {
    const portalId = localStorage.getItem('portalId'); // Retrieve portalId from localStorage

    // Check if the portalId matches the specific portal
    this.isSpecificPortal = portalId === '2180';

    if (!this.isSpecificPortal) {
      this.siteStateService.selectedSite$.pipe(
        filter(site => site !== null),
        switchMap((site) => {
          return this.siteService.deepLoadSite$((site as SiteVM).siteId!);
        })
      ).subscribe({
        next: (sites) => {
          console.log(sites);
          this.loadFpItemsIntoMainItems(sites);
          this.hideAllFpItems();
        }
      });
    }
  }

  private loadFpItemsIntoMainItems(fpCategories: FPCategoryVM[]) {
    this.mainItems[0].fpResourceCategories.next(
      fpCategories.filter(fpc => fpc.locationId === 'I').sort((a, b) => a.order! - b.order!)
    );
    this.mainItems[1].fpResourceCategories.next(
      fpCategories.filter(fpc => fpc.locationId === 'B' || fpc?.locationId === 'A').sort((a, b) => a.order! - b.order!)
    );
    this.mainItems[2].fpResourceCategories.next(
      [
        ...fpCategories.filter(fpc => fpc.locationId === 'O'),
        ...this.feedReportsWithExtraStaticFpCategories()
      ].sort((a, b) => a.order! - b.order!)
    );
  }

  feedReportsWithExtraStaticFpCategories(): FPCategoryVM[] {
    return [
      {
        categoryName: 'Event History',
        fpicon: { iconPath: '19-EventHistory.svg' },
        report: { reportUrl: 'rptEventHistoryChart' },
        showFormat: 'YMWD',
        defaultFormat: '1Y',
        order: 1
      },
      {
        categoryName: 'Alarm Indicators',
        fpicon: { iconPath: '22-PolicyIndicator.svg' },
        report: { reportUrl: 'rptPolicyKPIStatus' },
        showFormat: 'D',
        defaultFormat: '0D',
        order: 2
      },
      {
        categoryName: 'Maintenance',
        fpicon: { iconPath: '21-Diagnostic.svg' },
        report: { reportUrl: 'rptServiceStatus' },
        showFormat: '',
        defaultFormat: '',
        order: 3
      }
    ];
  }

  getFpItemIcon(iconPath: string | undefined): string {
    if (!iconPath) return '';
    const realIconPath = iconPath.replace('.png', '.svg');
    return `assets/images/svg/Items/${realIconPath}`;
  }

  showFpItems(mainItem: HTMLDivElement) {
    this.hideAllFpItems();
    mainItem.querySelector(".categories")?.classList.remove('hide');
  }

  private hideAllFpItems() {
    this.divs.forEach(elRef => {
      const div = elRef.nativeElement;
      div.querySelector('.categories')?.classList.add('hide');
    });
  }

  openReportModal(fpResourceCategory: FPCategoryVM, category: HTMLDivElement) {
    if (category.parentElement?.classList.contains('hide')) return;

    this.dialog.open(ModalReportsComponent, {
      width: '1100px',
      data: {
        category: fpResourceCategory,
        url: this.reportViewerURL$
      },
    });
  }

  drop(event: CdkDragDrop<any>, id: ('I' | 'B' | 'O' | 'A')[]) {
    const targetItem = this.mainItems.find(mainItem => mainItem.id.includes(id[0]));

    if (!targetItem) return;

    const targetCategories = targetItem.fpResourceCategories.getValue();

    const data = event.item.data as FPCategoryVM;

    this.siteService.updateFPCategoryOrderPerSite(
      this.siteStateService.selectedSiteSource.getValue()?.siteId!,
      data.fpcategoryId!,
      data.locationId!,
      event.currentIndex
    ).subscribe();
    moveItemInArray(targetCategories, event.previousIndex, event.currentIndex);
  }
}
